import { Duration } from "luxon";

// I LOVE CHATGPT 4O
export default function humanReadableDuration(milliseconds, maxParts = 2) {
    const duration = Duration.fromMillis(Math.abs(milliseconds)).shiftTo('years', 'months', 'days', 'hours', 'minutes', 'seconds');
    
    let parts = [];

    if (duration.years > 0) {
        parts.push(`${Math.floor(duration.years)} year${Math.floor(duration.years) > 1 ? 's' : ''}`);
    }
    if (duration.months > 0) {
        parts.push(`${Math.floor(duration.months)} month${Math.floor(duration.months) > 1 ? 's' : ''}`);
    }

    // Convert weeks to days if months or years are present
    let totalDays = duration.days;
    if (duration.months > 0 || duration.years > 0) {
        totalDays += Math.floor(duration.weeks * 7);
    } else {
        const weeks = Math.floor(duration.days / 7);
        totalDays = duration.days % 7;
        if (weeks > 0) {
        parts.push(`${weeks} week${weeks > 1 ? 's' : ''}`);
        }
    }

    if (totalDays > 0) {
        parts.push(`${Math.floor(totalDays)} day${Math.floor(totalDays) > 1 ? 's' : ''}`);
    }
    
    if (duration.hours > 0 && parts.length === 0) { // Only add hours if no larger units
        parts.push(`${Math.floor(duration.hours)} hour${Math.floor(duration.hours) > 1 ? 's' : ''}`);
    }
    if (duration.minutes > 0 && parts.length === 0) { // Only add minutes if no larger units
        parts.push(`${Math.floor(duration.minutes)} minute${Math.floor(duration.minutes) > 1 ? 's' : ''}`);
    }
    if (duration.seconds > 0 && parts.length === 0) { // Only add seconds if no larger units
        parts.push(`${Math.floor(duration.seconds)} second${Math.floor(duration.seconds) > 1 ? 's' : ''}`);
    }

    // Limit the number of parts to the configurable maxParts
    if (parts.length > maxParts) {
        parts = parts.slice(0, maxParts);
    }

    if (parts.length > 1) {
        const lastPart = parts.pop();
        return `${parts.join(', ')} and ${lastPart}`;
    }

    return parts.join(', ');
  }
