import { Stack } from "@mui/material"
import { useState } from 'react';
import UranusCard from "./cards/UranusCard"
import Acknowledgements from "./cards/Acknowledgements"
import Faq from "./cards/Faq"
import DataLoader from "../onboarding/DataLoader";
import Failed from "../onboarding/Failed";
import AdditionalPlanets from "./cards/AdditionalPlanets";
import PreciseTimeCard from "./cards/PreciseTimeCard";
import useLocalStorageState from "use-local-storage-state";

export default function BaseResult(props) {
    const [additionalState, setAdditionalState] = useState("loading")
    const [additionalData, setAdditionalData] = useState({})
    const [additionalError, setAdditionalError] = useState("")

    const [preciseTimeSwitch, setPreciseTimeSwitch] = useLocalStorageState('preciseTimeSwitch', {
        defaultValue: false
    })

    let switchAdditionalComponent = () => {
        if (additionalState === "loading") {
            return (
                <DataLoader setAppState={setAdditionalState} setPlanetData={setAdditionalData} setErrorMessage={setAdditionalError} url="https://isuranusinretrograde.com/api/v1/additionalPlanets" extraData={true}/>
            )
        } else if (additionalState === "errored") {
            return (
                <Failed errorMessage={additionalError}/>
            )
        } else if (additionalState === "loaded") {
            return (
                <AdditionalPlanets planetData={additionalData} preciseTimeSwitch={preciseTimeSwitch} />
            )
        }
    }

    return (
        <Stack spacing={2} sx={{ pb: 4 }}>
            <UranusCard planetData={props.planetData} preciseTimeSwitch={preciseTimeSwitch} />
            {switchAdditionalComponent()}
            <PreciseTimeCard preciseTimeSwitch={preciseTimeSwitch} setPreciseTimeSwitch={setPreciseTimeSwitch} />
            <Faq />
            <Acknowledgements />
        </Stack>
    )
}