import { Card, CardContent, Stack, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import humanReadableDuration from "../../utils/formatDuration";

export default function AdditionalPlanets(props) {
    let planets_sortorder = ["mercury", "venus", "mars barycenter", "jupiter barycenter", "saturn barycenter", "neptune barycenter", "pluto barycenter"]

    let returnColor = (value) => {
        if (value) {
            return "#2e7d32"
        } else {
            return "#d32f2f"
        }
    }

    const renderAdditionalPlanets = () => {
        let extraPlanets = []
        for (const key of planets_sortorder) {
            let value = props.planetData[key]
            let startingState = value.now_retrograde
            let exceededStartingState = false
            if (new Date().getTime() / 1000 > value.switch_date) {
                startingState = !startingState
                exceededStartingState = true
            }
    
            extraPlanets.push(
                <Typography variant="body1" component="div" sx={{ mb: 1 }}>
                    <b>{value.name}</b>: <span style={{ color: returnColor(startingState) }}>{startingState ? "🎉 IN RETROGRADE 🎉" : "😭 Not in retrograde 😭"}</span> {exceededStartingState ? "since" : "until"}&nbsp;
                    <Tooltip 
                        title={<Typography variant="body2">{!props.preciseTimeSwitch ? DateTime.fromSeconds(value.switch_date).toFormat("DD, tt") : (humanReadableDuration(new Date().getTime() - value.switch_date * 1000, 2) + (exceededStartingState ? " ago" : " from now"))}</Typography>} arrow>
                        <b>{!props.preciseTimeSwitch ? humanReadableDuration(new Date().getTime() - value.switch_date * 1000, 2) : DateTime.fromSeconds(value.switch_date).toFormat("DD, tt")}</b> {!props.preciseTimeSwitch ? exceededStartingState ? "ago" : "from now" : ""} 
                    </Tooltip>
                </Typography>
            )
        }

        return extraPlanets
    }

    console.log("Render " + props.preciseTimeSwitch)

    return (
        <Card sx={{ minWidth: "300px" }}>
            <CardContent sx={{ paddingBottom: "16px !important", width: "calc(100% - 32px)" }}>
                <Stack alignItems="left" justifyContent="left">
                    <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                        Curious if the other planets are in retrograde?
                    </Typography>
                    {renderAdditionalPlanets()}
                    <Stack alignItems="center" justifyContent="center" textAlign="center" sx={{ marginTop: "12px" }}>
                        <Typography variant="body2" component="div">
                            (retrograde data updated on {props.planetData.updated} - all times are in your local time)
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}