import './App.css';
import { useState } from 'react';
import DataLoader from './onboarding/DataLoader';
import BaseResult from './result/BaseResult';
import Failed from './onboarding/Failed';

export default function Base() {
  const [appState, setAppState] = useState("loading")
  const [planetData, setPlanetData] = useState({})
  const [errorMessage, setErrorMessage] = useState("")

  // Set effects to persist states in local storage
  // I don't know if we can allow useeffect to hit multiple variables.
  // Separating them for now and then we'll try.

  if (appState === "loading") {
    return (
        <DataLoader setAppState={setAppState} setPlanetData={setPlanetData} setErrorMessage={setErrorMessage} url="https://isuranusinretrograde.com/api/v1/retrograde"/>
    )
  } else if (appState === "errored") {
    return (
        <Failed errorMessage={errorMessage}/>
    )
  } else if (appState === "loaded") {
    return (
        <BaseResult planetData={planetData} />
    )
  }
}