import { Card, CardContent, Stack, Tooltip, Typography } from "@mui/material"
import { DateTime, Duration } from "luxon"
import { useState } from "react"
import humanReadableDuration from "../../utils/formatDuration"

export default function UranusCard(props) {
    const noText = [
        "No, your 3-month situationship will not turn into a relationship (sorry to break it to you)",
        "Men continue to be very emotionally unintelligent.",
        "Fuck this, I'm out",
        "That 7'2 guy from Bumble will actually be 5'6.",
        "The MBTA is probably on fire right now.",
        "Knock knock, who's there? Fuck, it's the delusions back it at again",
        "Crying so much I'm filling an olympic sized swimming pool rn",
        "That's not very demure or very mindful of Uranus.",
        "You will not be holding toes with your ex anytime soon.",
        "Might as well buy Tinder Premium. And Bumble Premium. And Hinge Premium.",
        "It's recommended you check your anus for any monsters that may be there.",
        "Yes, you have to do your work today - the Uranus in retrograde excuse isn't valid anymore.",
        "No, you do not need Dunkin for the 50th time this week."
    ];

    const yesText = [
        "Yes, your 3-month situationship will turn into a relationship!",
        "Men are emotionally intelligent now!",
        "Now that's very mindful and very demure of Uranus!",
        "The man you've longed for will be part of your symphony!",
        "Yes! Text your ex with zero hesitations!",
        "You'll get run over by a car and get a huge settlement from it!",
        "You'll become a millionare from foot finder!",
        "That sugar daddy on Seeking.com will actually pay you today!",
        "That 7'2 guy from Bumble will actually be 7'2!",
        "Forgot to do your work? Just tell your boss Uranus was in retrograde!",
        "The MBTA will actually work today!",
        "Yes, get that tattoo or piercing you've always wanted (you're gonna be extra hot after you do it)!",
        "You'll be holding toes with your ex this week!",
        "All the baddies will be eyeing you today!!",
        "The ex you've always wanted to come back will be crawling into your texts today!",
        "You'll meet the love of your life literally in the next hour!"
    ]

    const [startingState, setStartingState] = useState(props.planetData.retrograde)
    const [switchTS, setSwitchTS] = useState(props.planetData.switch_date)
    const [switchSeconds, setSwitchSeconds] = useState(switchTS - (new Date().getTime() / 1000))
    const [noTextNumber, setNoTextNumber] = useState(Math.floor(Math.random() * noText.length))
    const [yesTextNumber, setYesTextNumber] = useState(Math.floor(Math.random() * yesText.length))

    setTimeout(() => {
        setSwitchSeconds(switchTS - (new Date().getTime() / 1000))
    }, 1000)

    let returnNoText = () => {
        return noText[noTextNumber]
    }

    let returnYesText = () => {
        return yesText[yesTextNumber]
    }

    let returnText = () => {
        if (startingState && switchSeconds < 86400 && new Date().getTime() / 1000 < switchTS) {
            return (
                <>
                    <Typography variant="h5" component="div">
                        Uranus is in retrograde, but leaves it in...
                    </Typography>
                    <Typography variant="h3" component="div">
                        {Duration.fromMillis(switchSeconds * 1000).toFormat("hh:mm:ss")}
                    </Typography>
                    <Typography variant="body1" component="div">
                        Please ensure you've made preparations for your life to fall apart.
                    </Typography>
                </>
            )
        } else if ((startingState && new Date().getTime() / 1000 < switchTS) || (!startingState && new Date().getTime() / 1000 > switchTS)) {
            return (
                <>
                    <Typography variant="h3" component="div" sx={{ color: "#2e7d32", fontWeight: 700 }}>
                    🤩🎉 YES! 😫🐒
                    </Typography>
                    <Typography variant="body1" component="div">
                        Uranus is in retrograde! {returnYesText()}
                    </Typography>
                </>
            )
        } else if (!startingState && switchSeconds < 86400 && new Date().getTime() / 1000 < switchTS) {
            return (
                <>
                <Typography variant="h5" component="div">
                    Uranus isn't in retrograde, but goes retrograde in...
                </Typography>
                <Typography variant="h3" component="div">
                    {Duration.fromMillis(switchSeconds * 1000).toFormat("hh:mm:ss")}
                </Typography>
                <Typography variant="body1" component="div">
                    I hope you're finishing final preparations to your anus for this moment(an)us occasion.
                </Typography>
                </>
            )
        } else {
            return (
                <>
                    <Typography variant="h3" component="div" sx={{ color: "#d32f2f"}}>
                    🥲😪 NO 😭😭
                    </Typography>
                    <Typography variant="body1" component="div">
                        Uranus is not in retrograde. {returnNoText()}
                    </Typography>
                </>
            )
        }
    }

    let returnNextRetrograde = () => {
        if (startingState) {
            if (new Date().getTime() / 1000 > switchTS) {
                // This is a horrible chain of if statements, but whatever.
                if (props.preciseTimeSwitch) {
                    return (
                        <>
                            Uranus exited retrograde on&nbsp;
                            <Tooltip title={<Typography variant="body2">{humanReadableDuration(switchSeconds * 1000, 3)} ago</Typography>} arrow>
                                <b>{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</b>
                            </Tooltip>. Your anus is now sad.
                        </>
                    )
                }

                return (
                    <>
                        Uranus exited retrograde&nbsp;
                        <Tooltip title={<Typography variant="body2">{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</Typography>} arrow>
                            <b>{humanReadableDuration(switchSeconds * 1000, 3)}</b>
                        </Tooltip>&nbsp;ago. Your anus is now sad.
                    </>
                )
            } else {
                if (switchSeconds >= 86400) {
                    if (props.preciseTimeSwitch) {
                        return (
                            <>
                                Uranus will not be in retrograde on&nbsp;
                                <Tooltip title={<Typography variant="body2">{humanReadableDuration(switchSeconds * 1000, 3)} from now</Typography>} arrow>
                                    <b>{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</b>
                                </Tooltip>. Please ensure your anus is prepared for this tragic date.
                            </>
                        )
                    }

                    return (
                        <>
                            Uranus will not be in retrograde in&nbsp;
                            <Tooltip title={<Typography variant="body2">{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</Typography>} arrow>
                                <b>{humanReadableDuration(switchSeconds * 1000, 3)}</b>
                            </Tooltip>&nbsp;from now. Please ensure your anus is prepared for this tragic date.
                        </>
                    )
                }

                return (
                    <> 
                        Uranus will not be in retrograde starting on <b>{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</b>.
                    </>
                )
            }
        } else {
            if (new Date().getTime() / 1000 > switchTS) {
                if (props.preciseTimeSwitch) {
                    return (
                        <>
                            Uranus went into retrograde on&nbsp;
                            <Tooltip title={<Typography variant="body2">{humanReadableDuration(switchSeconds * 1000, 3)} ago</Typography>} arrow>
                                <b>{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</b>
                            </Tooltip>. Your anus has been blessed!
                        </>
                    )
                }

                return (
                    <>
                        Uranus went into retrograde&nbsp;
                        <Tooltip title={<Typography variant="body2">{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</Typography>} arrow>
                            <b>{humanReadableDuration(switchSeconds * 1000, 3)}</b>
                        </Tooltip>&nbsp;ago. Your anus has been blessed!
                    </>
                )
            } else {
                if (switchSeconds >= 86400) {
                    if (props.preciseTimeSwitch) {
                        return (
                            <>
                                Uranus will be in retrograde on&nbsp;
                                <Tooltip title={<Typography variant="body2">{humanReadableDuration(switchSeconds * 1000, 3)} from now</Typography>} arrow>
                                    <b>{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</b>
                                </Tooltip>. Make sure you prepare your anus accordingly.
                            </>
                        )
                    }

                    return (
                        <>
                            Uranus will be in retrograde in&nbsp;
                            <Tooltip title={<Typography variant="body2">{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</Typography>} arrow>
                                <b>{humanReadableDuration(switchSeconds * 1000, 3)}</b>
                            </Tooltip>&nbsp;from now. Make sure you prepare your anus accordingly.
                        </>
                    )
                }
                return (
                    <>
                        Uranus will be in retrograde starting on <b>{DateTime.fromSeconds(props.planetData.switch_date).toFormat("DDD 'at' tt")}</b>.
                    </>
                )
            }
        }
    }

    return (
        <Card sx={{ minWidth: "300px" }}>
            <CardContent sx={{ paddingBottom: "16px !important", width: "calc(100% - 32px)" }}>
                <Stack spacing={1} alignItems="center" justifyContent="center" textAlign="center">
                    {returnText()}<br></br>
                    <Typography variant="body1" component="div">
                        {returnNextRetrograde()}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}