import { Card, CardContent, Stack, Typography } from "@mui/material";

export default function Failed(props) {
    return (
        <Card sx={{ minWidth: "300px" }}>
            <CardContent>
                <Stack spacing={2} alignItems="center" justifyContent="center">
                    <Typography variant="body1" component="div">
                        {props.errorMessage}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}