import { Card, CardContent, FormControlLabel, FormHelperText, FormGroup, Switch } from "@mui/material";

export default function PreciseTimeCard({preciseTimeSwitch, setPreciseTimeSwitch}) {
    const handleChange = (event) => {
        setPreciseTimeSwitch(event.target.checked);
    }

    return (
        <Card sx={{ minWidth: "300px" }}>
            <CardContent sx={{ paddingBottom: "16px !important" }}>
                <FormGroup>
                    <FormControlLabel 
                        control={
                            <Switch 
                                checked={preciseTimeSwitch}
                                onChange={handleChange}
                            />
                        }
                        label="Show precise times"
                        />
                    <FormHelperText sx={{ marginTop: "0px" }}>Hover over any time field to see {preciseTimeSwitch ? "relative times" : "precise times"} for retrograde switches. This setting is saved in your browser.</FormHelperText>
                </FormGroup>
            </CardContent>
        </Card>
    )
}