import { Card, CardContent, Stack, Typography } from "@mui/material"

export default function Acknowledgements() {
    return (
        <Card sx={{ minWidth: "300px" }}>
            <CardContent sx={{ paddingBottom: "16px !important" }}>
                <Stack>
                    <Typography variant="body1" component="div">
                        Big shoutout to William Herschel who discovered the 7th planet from the Sun and Johann Elert Bode who suggested the name Uranus. Inspired by an intrusive thought that won.
                    </Typography>
                    <Typography variant="caption" sx={{ marginTop: "12px" }}>
                        v2.0.0 · &copy; 2023-2024 <a href="https://owenthe.dev" target="_blank" rel="noreferrer">owenthe.dev</a>
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}