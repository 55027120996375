import { Card, CardContent, Stack, Typography } from "@mui/material"

export default function Faq() {
    return (
        <Card sx={{ minWidth: "300px" }}>
            <CardContent sx={{ paddingBottom: "16px !important" }}>
                <Stack alignItems="left" justifyContent="left">
                    <Typography variant="h4" component="div" sx={{ mb: 2 }}>
                        FAQ
                    </Typography>
                    <Typography variant="h6" component="div">
                        How do you pronounce Uranus?
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mb: 2 }}>
                        Definitely not URINE-us. More like your anus. 
                    </Typography>
                    <Typography variant="h6" component="div">
                        What happens when Uranus is in retrograde?
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mb: 2 }}>
                        Side effects may include (but are not limited to) delusionality, longing for all your B or J-named exes (including that 3-month situationship), strong desires to commit crimes, and becoming gay.
                    </Typography>
                    <Typography variant="h6" component="div">
                        Why would I care that Uranus is in retrograde?
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mb: 2}}>
                        Because Uranus being in retrograde can influence a lot of things. Trust me bro. I know astrology super well.
                    </Typography>
                    <Typography variant="h6" component="div">
                        What's the background image?
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mb: 2}}>
                        Uranus, as imaged by Voyager 2 in 1986. That's right, NASA sent a probe to your anus to image it, even if you weren't alive yet.
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ mt: 2, mb: 1 }}>
                        Nerdy FAQ
                    </Typography>
                    <Typography variant="h6" component="div">
                        How accurate is the retrograde data (especially the times)?
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mb: 2}}>
                        About as accurate as I can get without a supercomputer. I'm using the high-precision Skyfield python library for calculations w/ the latest DE440 ephemeris from JPL for planetary positions, so the second-level precision should be about as good as it gets (and it's way more precise than any almanac or website on the internet to begin with)
                    </Typography>
                    <Typography variant="h6" component="div">
                        How often is retrograde data updated?
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mb: 2}}>
                        The actual retrograde timings are updated daily at 12 AM Eastern Time. This site serves a cached version since running the calculations takes a while. However, the site has built-in smarts to ensure that the right retrograde status is being shown. In a future upgrade the site will automatically update the page and alert you when a retrograde change occurs.
                    </Typography>
                    <Typography variant="h6" component="div">
                        What classifies as a planet being in retrograde for this website?
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mb: 2}}>
                        In the script that runs calculations, retrograde is achieved when the Right Ascension of a planet decreases over a period of time (in this case, 1 second). Otherwise, the planet isn't in retrograde. Right ascension is basically the astrological equivalent of longitude - making it easy to figure out if a planet is going "backwards" (RA decreasing over time, in retrograde) or "forwards" (RA increasing over time, not in retrograde) across the sky apparent to Earth's celestial sphere. You can read more on Wikipedia about <a href="https://en.wikipedia.org/wiki/Right_ascension" target="_blank" rel="noreferrer">Right ascension</a> and <a href="https://en.wikipedia.org/wiki/Apparent_retrograde_motion" target="_blank" rel="noreferrer">Apparent retrograde motion</a>.<br></br><br></br>
                        I have also fixed a bug lately in which the script doing calculations was thinking a planet was in retrograde when it wasn't. This hasn't affected Uranus retrograde calculations but it was affecting some of the other planets.
                    </Typography>
                    <Typography variant="h6" component="div">
                        Where's the source code for the website?
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ mb: 2}}>
                        Once the site is done with the bulk of the features I'll post the source code online so you can take a peek at how it all works.
                    </Typography>
                    <Typography variant="h6" component="div">
                        How many hours do you have in Kerbal Space Program?
                    </Typography>
                    <Typography variant="body1" component="div">
                        478.6 hours as of July 10, 2023 (i don't play the game a ton these days)
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}