import { Card, CardContent, CircularProgress, Stack, Typography } from "@mui/material"
import { useEffect } from "react"


export default function DataLoader(props) {
    useEffect(() => {
        fetch(props.url)
        .then(response => {
            if (response.status !== 200) {
                props.setAppState("errored")
                props.setErrorMessage(`Failed to fetch retrograde data (HTTP status code ${response.status} != 200)`)
                return
            }

            return response
        })
        .then(response => response.json())
        .then(response => {
            props.setPlanetData(response)
            props.setAppState("loaded")
        })
        .catch(error => {
            props.setAppState("errored")
            props.setErrorMessage(`Failed to fetch retrograde data with fetch error: ${error.text()}`)
        })
    })

    return (
        <Card sx={{ minWidth: "300px" }}>
            <CardContent>
                <Stack spacing={2} alignItems="center" justifyContent="center">
                    <CircularProgress />
                    <Typography variant="h6" component="div">
                        {props.extraData ? "Loading extra retrograde data..." : "Loading retrograde data..."}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}