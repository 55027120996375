import { AppBar, Link, Typography } from "@mui/material";

export default function Header() {
    return (
        <AppBar position="static" sx={{ marginBottom: 5 }}>
            <Typography variant="h6" display="inline" sx={{ flexGrow: 1, padding: 2, marginLeft: 1}}>
                <Link href="/" underline="hover" variant="h6" color="inherit">
                    Is Uranus In Retrograde?
                </Link>
            </Typography>
        </AppBar>
    )
}