import './App.css';
import { Box, Container } from '@mui/material';
import Header from './header/Header';
import Base from './Base';

export default function App() {

  return (
    <Box sx={{ flexGrow: 1}}>
      <Header />
      <Container maxWidth="md">
        <Base />
      </Container>
    </Box>
  )
}
